<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :style="`
        --var-submit-btn-color: ${opt_in_settings_button_color_form.setting_value ? opt_in_settings_button_color_form.setting_value : '#2f7eed'};
        --var-submit-btn-color-light: ${opt_in_settings_button_color_form.setting_value ? opt_in_settings_button_color_form.setting_value + 20 : '#2f7eed20'};
        --var-submit-btn-txt-color: ${opt_in_settings_button_text_color_form.setting_value ? opt_in_settings_button_text_color_form.setting_value : '#ffffff'};
        --var-benefit-text-color: ${benefit_setting_bullet_text_color_form.setting_value ? benefit_setting_bullet_text_color_form.setting_value : '#121525'};
        --var-bullet-icon-size: ${benefit_setting_bullet_icon_size_form.setting_value ? benefit_setting_bullet_icon_size_form.setting_value + 'px' : '20px'};
        `">
        <div class="global_setting">
            <div class="main_panel">
                <div class="loader-box" v-if="optinSettingsLoaderSatatus && !mounted"><quote-loader /></div>
                <Form v-show="!optinSettingsLoaderSatatus || mounted" v-slot="{ errors }" ref="playbook-optin-form" class="playbook-optin-form">
                    <div class="flex">
                        <button class="close_btn pointer" @click="closeModal()"><i class="fas fa-times"></i></button>
                        <div class="preview_button" @click="fullPreview = true;">Preview</div>
                    </div>
                    <div class="optin_wrapper">
                        <div class="optin_header">
                            <div class="company_label">
                                <div class="tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                                    <label>Tutorials</label><img src="@/assets/images/play.svg">
                                    <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                        <ul>
                                            <li @click="handleTutorialVideo()">How to build a playbook opt-in page in 5-minutes.</li>
                                        </ul>
                                    </div>
                                </div>

                                Exclusively From
                                <span class="tag">
                                    {{ company_name_form.setting_value }}
                                    <span class="edit_btn" @click="handleInlineEdit($event, '.company_label')"><i class="fas fa-pen"></i></span>
                                </span>
                                <div class="rename_fld" :class="{ 'has-error': errors.company_name }">
                                    <Field autocomplete="off" type="text" name="company_name" v-model="company_name_form.setting_value" rules="required" label="company name" />
                                    <ErrorMessage name="company_name" class="text-danger" />
                                    <button type="button" class="save_btn" @click.stop="handleInlineEdit($event, '.company_label', company_name_form, 'company_name')">Save</button>
                                </div>
                            </div>
                            <div class="optin_title">
                                <label>{{ headline_form.setting_value }} <span class="edit_btn" @click="handleInlineEdit($event, '.optin_title')"><i class="fas fa-pen"></i></span></label>
                                <div class="rename_fld" :class="{ 'has-error': errors.optin_title }">
                                    <Field autocomplete="off" type="text" name="optin_title" v-model="headline_form.setting_value" rules="required" label="optin title" />
                                    <ErrorMessage name="optin_title" class="text-danger" />
                                    <button type="button" class="save_btn" @click.stop="handleInlineEdit($event, '.optin_title', headline_form, 'optin_title')">Save</button>
                                </div>
                            </div>
                            <div class="optin_subtitle">
                                <label>{{ sub_headline_form.setting_value }} <span class="edit_btn" @click="handleInlineEdit($event, '.optin_subtitle')"><i class="fas fa-pen"></i></span></label>
                                <div class="rename_fld" :class="{ 'has-error': errors.optin_subtitle }">
                                    <Field autocomplete="off" type="text" name="optin_subtitle" v-model="sub_headline_form.setting_value" rules="required" label="optin subtitle" />
                                    <ErrorMessage name="optin_subtitle" class="text-danger" />
                                    <button type="button" class="save_btn" @click.stop="handleInlineEdit($event, '.optin_subtitle', sub_headline_form, 'optin_subtitle')">Save</button>
                                </div>
                            </div>
                        </div>
                        <div class="optin_body">
                            <div class="flex w-100">
                                <span class="setting_btn" @click="handleModal('Benefit')"><i class="fas fa-cog"></i>Benefit Settings</span>
                            </div>
                            <div class="img_vid_wpr">
                                <template v-if="media_setting_type_form.setting_value == 'link' || media_setting_type_form.setting_value == 'embed'">
                                    <div class="video_wpr" v-if="(media_setting_type_form.setting_value == 'link' && media_setting_video_link_value_form.setting_value) || (media_setting_type_form.setting_value == 'embed' && media_setting_embedded_video_value_form.setting_value)">
                                        <div class="video-overlay" @click="showVideo = true"></div>
                                        <div v-if="media_setting_type_form.setting_value == 'link' && media_setting_video_link_value_form.setting_value" v-html="parseEmbedCode(media_setting_video_link_value_form.setting_value)"></div>
                                        <div v-else-if="media_setting_type_form.setting_value == 'embed' && media_setting_embedded_video_value_form.setting_value" v-html="media_setting_embedded_video_value_form.setting_value"></div>
                                    </div>
                                    <img v-if="(media_setting_type_form.setting_value == 'link' && !media_setting_video_link_value_form.setting_value) || (media_setting_type_form.setting_value == 'embed' && !media_setting_embedded_video_value_form.setting_value)" src="@/assets/images/thumb/video-placeholder.svg">
                                </template>
                                <div v-if="media_setting_type_form.setting_value == 'image'">
                                    <img :src="media_setting_image_value_form.setting_value" v-if="media_setting_image_value_form.setting_value">
                                    <img src="@/assets/images/thumb/default-video-size.svg" v-else>
                                </div>
                                <span class="edit_btn" @click="handleModal('Media')"><i class="fas fa-cog"></i></span>
                            </div>
                            <div class="optin_features">
                                <ul>
                                    <li class="feature_item" v-for="(feature, f) of benefits_form.setting_value" :key="f">
                                        <span class="icon" v-if="benefit_setting_bullet_icon_size_form.setting_value > 0">
                                            <newsletter-icon :icon="benefit_setting_bullet_icon_type_form.setting_value" :color="benefit_setting_bullet_icon_color_form.setting_value" />
                                        </span>
                                        <label>
                                            {{ feature }}
                                            <span class="edit_btn" @click="handleInlineEdit($event, '.feature_item')"><i class="fas fa-pen"></i></span>
                                            <span class="delete_btn" @click="handleDeleteBenefit(benefits_form.setting_value, f)"><img src="@/assets/images/trash.svg"></span>
                                        </label>
                                        <div class="rename_fld" :class="{ 'has-error': errors.name }">
                                            <Field autocomplete="off" type="text" :name="`feature_name_${f}`" v-model="benefits_form.setting_value[f]" />
                                            <ErrorMessage :name="`feature_name_${f}`" class="text-danger" />
                                            <button type="button" class="save_btn" @click.stop="handleInlineEdit($event, '.feature_item', benefits_form, `feature_name_${f}`)">Save</button>
                                        </div>
                                    </li>
                                </ul>
                                <div class="add_benefit" @click="addBenefit()" v-if="benefits_form.setting_value.length < 6">+ Add Benefit</div>
                                <div class="guide_btn_wpr">
                                    <div class="guide_btn" style="background: var(--var-submit-btn-color); color: var(--var-submit-btn-txt-color);">
                                        <label>{{ opt_in_settings_button_text_form.setting_value }}<span class="edit_btn" @click="handleInlineEdit($event, '.guide_btn')"><i class="fas fa-pen"></i></span></label>
                                        <div class="rename_fld" :class="{ 'has-error': errors.opt_in_settings_button_text }">
                                            <Field autocomplete="off" type="text" name="opt_in_settings_button_text" v-model="opt_in_settings_button_text_form.setting_value" rules="required" label="button text" />
                                            <ErrorMessage name="opt_in_settings_button_text" class="text-danger" />
                                            <button type="button" class="save_btn" @click.stop="handleInlineEdit($event, '.guide_btn', opt_in_settings_button_text_form, 'opt_in_settings_button_text')">Save</button>
                                        </div>
                                    </div>
                                    <div class="security">
                                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.54927 9.84927C1.359 9.84927 1.19727 9.78267 1.06407 9.64948C0.930884 9.51629 0.864288 9.35456 0.864288 9.16428V4.20958C0.864288 4.01931 0.930884 3.85758 1.06407 3.72439C1.19727 3.5912 1.359 3.5246 1.54927 3.5246H2.34842V2.42863C2.34842 1.82737 2.55962 1.31553 2.98202 0.89313C3.40443 0.470724 3.91626 0.259521 4.51753 0.259521C5.11879 0.259521 5.63062 0.470724 6.05303 0.89313C6.47543 1.31553 6.68664 1.82737 6.68664 2.42863V3.5246H7.48578C7.67605 3.5246 7.83779 3.5912 7.97098 3.72439C8.10417 3.85758 8.17076 4.01931 8.17076 4.20958V9.16428C8.17076 9.35456 8.10417 9.51629 7.97098 9.64948C7.83779 9.78267 7.67605 9.84927 7.48578 9.84927H1.54927ZM1.54927 9.16428H7.48578V4.20958H1.54927V9.16428ZM4.51753 7.56599C4.76108 7.56599 4.96847 7.48227 5.13972 7.31483C5.31096 7.14739 5.39659 6.9457 5.39659 6.70977C5.39659 6.48144 5.31096 6.27404 5.13972 6.08757C4.96847 5.90111 4.76108 5.80787 4.51753 5.80787C4.27398 5.80787 4.06658 5.90111 3.89533 6.08757C3.72409 6.27404 3.63847 6.48144 3.63847 6.70977C3.63847 6.9457 3.72409 7.14739 3.89533 7.31483C4.06658 7.48227 4.27398 7.56599 4.51753 7.56599ZM3.0334 3.5246H6.00165V2.42863C6.00165 2.01764 5.85705 1.66754 5.56783 1.37832C5.27862 1.08911 4.92852 0.944503 4.51753 0.944503C4.10654 0.944503 3.75643 1.08911 3.46722 1.37832C3.17801 1.66754 3.0334 2.01764 3.0334 2.42863V3.5246ZM1.54927 9.16428V4.20958V9.16428Z" :fill="opt_in_settings_pop_up_security_icon_color_form.setting_value ? opt_in_settings_pop_up_security_icon_color_form.setting_value : '#2f7eed'"/>
                                        </svg>
                                        <label>{{ opt_in_settings_security_text_form.setting_value }}<span class="edit_btn" @click="handleInlineEdit($event, '.security')"><i class="fas fa-pen"></i></span></label>
                                        <div class="rename_fld" :class="{ 'has-error': errors.opt_in_settings_pop_up_privacy_statement_text }">
                                            <Field autocomplete="off" type="text" name="opt_in_settings_pop_up_privacy_statement_text" v-model="opt_in_settings_security_text_form.setting_value" rules="required" label="privacy statement text" />
                                            <ErrorMessage name="opt_in_settings_pop_up_privacy_statement_text" class="text-danger" />
                                            <button type="button" class="save_btn" @click.stop="handleInlineEdit($event, '.security', opt_in_settings_security_text_form, 'opt_in_settings_security_text_form')">Save</button>
                                        </div>
                                    </div>
                                </div>
                                <span class="setting_btn" @click="handleModal('Opt-In')"><i class="fas fa-cog"></i><label>Opt-In Settings</label></span>
                            </div>
                        </div>
                        <div class="optin_footer">
                            <div class="copyright">
                                © {{ new Date().getFullYear() }} -
                                <div class="copyright_title">
                                    <label>{{ footer_settings_copyright_text_form.setting_value }}<span class="edit_btn" @click="handleInlineEdit($event, '.copyright_title')"><i class="fas fa-pen"></i></span></label>
                                    <div class="rename_fld" :class="{ 'has-error': errors.footer_settings_copyright_text }">
                                        <Field autocomplete="off" type="text" name="footer_settings_copyright_text" v-model="footer_settings_copyright_text_form.setting_value" rules="required" label="copyright" />
                                        <ErrorMessage name="footer_settings_copyright_text" class="text-danger" />
                                        <button type="button" class="save_btn" @click.stop="handleInlineEdit($event, '.copyright_title', footer_settings_copyright_text_form, 'footer_settings_copyright_text')">Save</button>
                                    </div>
                                </div>
                                <a :href="footer_settings_privacy_policy_link_form.setting_value ? footer_settings_privacy_policy_link_form.setting_value : '#'" class="privacy_policy" v-if="footer_settings_privacy_policy_enabled_form.setting_value">{{ footer_settings_privacy_policy_text_form.setting_value }}</a>
                            </div>
                            <span class="setting_btn" @click="handleModal('Footer')"><i class="fas fa-cog"></i><label>Footer Settings</label></span>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        <div class="modal" v-if="guideForm" :class="{ active : guideForm }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>{{settingType}} Settings</h1>
                    </div>
                    <ul class="tab_sec" v-if="settingType === 'Opt-In'">
                        <li @click="tab = 1" :class="{'active' : tab == 1}">Opt-In</li>
                        <li @click="tab = 2" :class="{'active' : tab == 2}">Social Sharing</li>
                    </ul>
                    <button class="close_btn left_out" @click="guideForm = false;"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <Form v-slot="{ errors }" class="optin-setting-form" ref="optin-setting-form">
                    <div class="modal_body">
                        <div class="setting_wpr" v-if="settingType === 'Media'">
                            <div class="edit_section">
                                <div class="capsule_elm mt-2">
                                    <h5>Video Link</h5>
                                    <label for="video_url" class="switch_option capsule_btn">
                                        <input type="radio" id="video_url" value="link" v-model="media_setting_type_form.setting_value" @change="handleAutosave(media_setting_type_form, 'media_setting_type_form')" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="form_grp pt-0" v-if="media_setting_type_form.setting_value == 'link'">
                                    <div class="group_item">
                                        <div class="field_wpr m-0">
                                            <Field autocomplete="off" type="text" name="optin_video_link" v-model="media_setting_video_link_value_form.setting_value" placeholder="ex:https://www.youtube.com/watch?v=eaRQF-7hhmo" rules="url" @focusout="handleAutosave(media_setting_video_link_value_form, 'media_setting_video_link_value_form')" label="video link" />
                                        </div>
                                    </div>
                                </div>
                                <div class="capsule_elm">
                                    <h5>Embeded Video</h5>
                                    <label for="embed_video" class="switch_option capsule_btn">
                                        <input type="radio" id="embed_video" value="embed" v-model="media_setting_type_form.setting_value" @change="handleAutosave(media_setting_type_form, 'media_setting_type_form')" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="form_grp pt-0" v-if="media_setting_type_form.setting_value == 'embed'">
                                    <div class="group_item">
                                        <div class="field_wpr m-0">
                                            <Field autocomplete="off" type="textarea" name="optin_video_embed_code" v-model="media_setting_embedded_video_value_form.setting_value" label="embed code">
                                                <textarea name="optin_video_embed_code" v-model="media_setting_embedded_video_value_form.setting_value" @focusout="handleAutosave(media_setting_embedded_video_value_form, 'media_setting_embedded_video_value_form')" placeholder="embeded code here.."></textarea>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div class="capsule_elm">
                                    <h5>Image</h5>
                                    <label for="add_image" class="switch_option capsule_btn">
                                        <input type="radio" id="add_image" value="image" v-model="media_setting_type_form.setting_value" @change="handleAutosave(media_setting_type_form, 'media_setting_type_form')" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="upload_image" v-if="media_setting_type_form.setting_value == 'image'">
                                    <image-library v-model="media_setting_image_value_form.setting_value" image-type="video-size" upload-text="Image" />
                                </div>
                            </div>
                            <a href="https://www.canva.com/design/DAGJWEFhcss/Ndyst7zk3vL0Buv2alBgjg/view?utm_content=DAGJWEFhcss&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="canva_template_cta"><img src="@/assets/images/canva.png"> Template Link</a>
                        </div>
                        <div class="setting_wpr" v-if="settingType === 'Benefit'">
                            <h3 class="sub_header mt-1">Bullets</h3>
                            <div class="edit_section">
                                <div class="setting_wpr">
                                    <div class="icon_section">
                                        <div class="group_item p-0 mb-2"><label class="input_label">Bullet Icons</label></div>
                                        <ul>
                                            <li v-for="(icon, i) in bulletIcons" :key="i">
                                                <label :for="`icon-${i}`" :class="{'active' : icon == benefit_setting_bullet_icon_type_form.setting_value}">
                                                    <input type="radio" name="select_icon" :id="`icon-${i}`" :value="icon" v-model="benefit_setting_bullet_icon_type_form.setting_value" @change="handleAutosave(benefit_setting_bullet_icon_type_form, 'benefit_setting_bullet_icon_type_form')" hidden>
                                                    <img :src="require(`@/assets/images/${icon}.svg`)" alt="">
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Bullet Icon Size <a class="reset-default" @click="resetToDefault('benefit_setting_bullet_icon_size_form')">Reset to default</a></label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.benefit_setting_bullet_icon_size }">
                                                <Field autocomplete="off" type="number" name="benefit_setting_bullet_icon_size" v-model="benefit_setting_bullet_icon_size_form.setting_value" @focusout="handleAutosave(benefit_setting_bullet_icon_size_form, 'benefit_setting_bullet_icon_size_form')" placeholder="20" rules="required" label="bullet icon size" />
                                            </div>
                                            <ErrorMessage name="benefit_setting_bullet_icon_size" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Bullet Icon Color <a class="reset-default" @click="resetToDefault('benefit_setting_bullet_icon_color_form')">Reset to default</a></label>
                                            <Field autocomplete="off" name="benefit_setting_bullet_icon_color" v-model="benefit_setting_bullet_icon_color_form.setting_value" type="text" label="element color" rules="required">
                                                <color-picker v-model="benefit_setting_bullet_icon_color_form.setting_value" :classes="{ 'has-error': errors.benefit_setting_bullet_icon_color }" />
                                            </Field>
                                            <ErrorMessage name="benefit_setting_bullet_icon_color" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Bullet Text Color <a class="reset-default" @click="resetToDefault('benefit_setting_bullet_text_color_form')">Reset to default</a></label>
                                            <Field autocomplete="off" name="benefit_setting_bullet_text_color" v-model="benefit_setting_bullet_text_color_form.setting_value" type="text" label="element color" rules="required">
                                                <color-picker v-model="benefit_setting_bullet_text_color_form.setting_value" :classes="{ 'has-error': errors.benefit_setting_bullet_text_color }" />
                                            </Field>
                                            <ErrorMessage name="benefit_setting_bullet_text_color" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="setting_wpr" v-if="settingType === 'Opt-In'">
                            <template v-if="tab == 1">
                                <h3 class="sub_header mt-1">Page Submit Button</h3>
                                <div class="edit_section">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Button Text <a class="reset-default" @click="resetToDefault('opt_in_settings_button_text_form')">Reset to default</a></label>
                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.opt_in_settings_button_text }">
                                                <Field autocomplete="off" type="text" name="opt_in_settings_button_text" v-model="opt_in_settings_button_text_form.setting_value" @focusout="handleAutosave(opt_in_settings_button_text_form, 'opt_in_settings_button_text_form')" placeholder="Send Me The Guide!" rules="required" label="button text" />
                                            </div>
                                            <ErrorMessage name="opt_in_settings_button_text" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Button Color <a class="reset-default" @click="resetToDefault('opt_in_settings_button_color_form')">Reset to default</a></label>
                                            <Field autocomplete="off" name="opt_in_settings_button_color" v-model="opt_in_settings_button_color_form.setting_value" type="text" label="button color" rules="required">
                                                <color-picker v-model="opt_in_settings_button_color_form.setting_value" :classes="{ 'has-error': errors.opt_in_settings_button_color }" />
                                            </Field>
                                            <ErrorMessage name="opt_in_settings_button_color" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Button Text Color <a class="reset-default" @click="resetToDefault('opt_in_settings_button_text_color_form')">Reset to default</a></label>
                                            <Field autocomplete="off" name="opt_in_settings_button_text_color" v-model="opt_in_settings_button_text_color_form.setting_value" type="text" label="button text color" rules="required">
                                                <color-picker v-model="opt_in_settings_button_text_color_form.setting_value" :classes="{ 'has-error': errors.opt_in_settings_button_text_color }" />
                                            </Field>
                                            <ErrorMessage name="opt_in_settings_button_text_color" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <h3 class="sub_header mt-5">Pop Up</h3>
                                <div class="edit_section">
                                    <div class="capsule_elm">
                                        <h5>Image</h5>
                                        <label for="opt_in_settings_pop_up_image_enabled" class="switch_option capsule_btn">
                                            <input type="checkbox" id="opt_in_settings_pop_up_image_enabled" :true-value="true" :false-value="false" v-model="opt_in_settings_pop_up_image_enabled_form.setting_value" @change="handleAutosave(opt_in_settings_pop_up_image_enabled_form, 'opt_in_settings_pop_up_image_enabled_form')" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="upload_image" v-if="opt_in_settings_pop_up_image_enabled_form.setting_value">
                                        <image-library v-model="opt_in_settings_pop_up_image_form.setting_value" image-type="video-size" upload-text="Image" />
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">
                                                Opt-in Headline
                                                <button type="button" class="reset-default mr-1" @click="resetToDefault('optin_heading_title')">Reset to default</button>
                                            </label>
                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.opt_in_settings_pop_up_opt_in_headline }">
                                                <Field autocomplete="off" type="text" name="opt_in_settings_pop_up_opt_in_headline"  v-model="opt_in_settings_pop_up_opt_in_headline_form.setting_value" @focusout="handleAutosave(opt_in_settings_pop_up_opt_in_headline_form, 'opt_in_settings_pop_up_opt_in_headline_form')" rules="required" label="headline" />
                                            </div>
                                            <ErrorMessage name="opt_in_settings_pop_up_opt_in_headline" class="text-danger" />
                                        </div>
                                    </div>
                                    <h3 class="sub_heading2 mt-4 mb-0">Opt-In Fields</h3>
                                    <ul class="optin_list">
                                        <li>
                                            <label for="opt_in_settings_pop_up_opt_in_fields_first_name_enabled" class="switch_option capsule_btn">
                                                <h5 class="large">First Name</h5>
                                                <input type="checkbox" id="opt_in_settings_pop_up_opt_in_fields_first_name_enabled" :true-value="true" :false-value="false" v-model="opt_in_settings_pop_up_opt_in_fields_first_name_enabled_form.setting_value" @change="handleAutosave(opt_in_settings_pop_up_opt_in_fields_first_name_enabled_form, 'opt_in_settings_pop_up_opt_in_fields_first_name_enabled_form')" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="opt_in_settings_pop_up_opt_in_fields_last_name_enabled" class="switch_option capsule_btn">
                                                <h5 class="large">Last Name</h5>
                                                <input type="checkbox" id="opt_in_settings_pop_up_opt_in_fields_last_name_enabled" :true-value="true" :false-value="false" v-model="opt_in_settings_pop_up_opt_in_fields_last_name_enabled_form.setting_value" @change="handleAutosave(opt_in_settings_pop_up_opt_in_fields_last_name_enabled_form, 'opt_in_settings_pop_up_opt_in_fields_last_name_enabled_form')" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="opt_in_settings_pop_up_opt_in_fields_email_enabled" class="switch_option capsule_btn">
                                                <h5 class="large">Email</h5>
                                                <input type="checkbox" id="opt_in_settings_pop_up_opt_in_fields_email_enabled" :true-value="true" :false-value="false" v-model="opt_in_settings_pop_up_opt_in_fields_email_enabled_form.setting_value" @change="handleAutosave(opt_in_settings_pop_up_opt_in_fields_email_enabled_form, 'opt_in_settings_pop_up_opt_in_fields_email_enabled_form')" disabled hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="opt_in_settings_pop_up_opt_in_fields_phone_enabled" class="switch_option capsule_btn">
                                                <h5 class="large">Phone</h5>
                                                <input type="checkbox" id="opt_in_settings_pop_up_opt_in_fields_phone_enabled" :true-value="true" :false-value="false" v-model="opt_in_settings_pop_up_opt_in_fields_phone_enabled_form.setting_value" @change="handleAutosave(opt_in_settings_pop_up_opt_in_fields_phone_enabled_form, 'opt_in_settings_pop_up_opt_in_fields_phone_enabled_form')" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                    </ul>
                                    <h3 class="sub_heading2 mt-4 mb-0">
                                        GDPR Permission
                                        <label for="gdpr" class="switch_option capsule_btn ml-auto">
                                            <input type="checkbox" id="gdpr" :true-value="true" :false-value="false" v-model="opt_in_settings_pop_up_opt_gdpr_permission_enabled_form.setting_value" @change="handleAutosave(opt_in_settings_pop_up_opt_gdpr_permission_enabled_form, 'opt_in_settings_pop_up_opt_gdpr_permission_enabled_form')" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </h3>
                                    <div class="form_grp" v-if="opt_in_settings_pop_up_opt_gdpr_permission_enabled_form.setting_value">
                                        <div class="group_item">
                                            <label class="input_label" for="gdpr_permission">
                                                Text
                                                <div class="flex-center">
                                                    <button type="button" class="reset-default mr-1" @click="resetToDefault('opt_in_settings_pop_up_gdpr_permission_text_form')">Reset to default</button>
                                                    <div class="dropdown mr-1" @click="gdprPersonalizeOption = !gdprPersonalizeOption">
                                                        <i class="fas fa-user-alt"></i>
                                                        <div class="drp_wrapper" v-if="gdprPersonalizeOption" :class="{ active : gdprPersonalizeOption }">
                                                            <ul>
                                                                <li v-for="(personalize, p) of defaultPersonalize" :key="p" @click="insertPersonalize(personalize, 'gdpr_permission', 'opt_in_settings_pop_up_gdpr_permission_text_form')">{{ personalize }}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.opt_in_settings_pop_up_gdpr_permission_text }">
                                                <Field autocomplete="off" type="text" name="opt_in_settings_pop_up_gdpr_permission_text" id="gdpr_permission" v-model="opt_in_settings_pop_up_gdpr_permission_text_form.setting_value" @focusout="handleAutosave(opt_in_settings_pop_up_gdpr_permission_text_form, 'opt_in_settings_pop_up_gdpr_permission_text_form')" label="gdpr permission text" rules="required" />
                                            </div>
                                            <ErrorMessage name="opt_in_settings_pop_up_gdpr_permission_text" class="text-danger" />
                                        </div>
                                    </div>
                                    <h3 class="sub_heading2 mt-4 mb-0">Submit Button</h3>
                                    <div class="form_grp">
                                        <div class="group_item" :class="{ 'has-error': errors.opt_in_settings_pop_up_submit_button_text }">
                                            <label class="input_label">Text <a class="reset-default" @click="resetToDefault('opt_in_settings_pop_up_submit_button_text_form')">Reset to default</a></label>
                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.opt_in_settings_pop_up_submit_button_text }">
                                                <Field autocomplete="off" type="text" name="opt_in_settings_pop_up_submit_button_text" v-model="opt_in_settings_pop_up_submit_button_text_form.setting_value" @focusout="handleAutosave(opt_in_settings_pop_up_submit_button_text_form, 'opt_in_settings_pop_up_submit_button_text_form')" placeholder="Send Me The Guide!" rules="required" label="submit button text" />
                                            </div>
                                            <ErrorMessage name="opt_in_settings_pop_up_submit_button_text" class="text-danger" />
                                        </div>
                                    </div>
                                    <h3 class="sub_heading2 mt-4 mb-0">Privacy Statement</h3>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Text <a class="reset-default" @click="resetToDefault('opt_in_settings_security_text_form')">Reset to default</a></label>
                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.opt_in_settings_security_text }">
                                                <Field autocomplete="off" type="text" name="opt_in_settings_security_text" v-model="opt_in_settings_security_text_form.setting_value" @focusout="handleAutosave(opt_in_settings_security_text_form, 'opt_in_settings_security_text_form')" rules="required" label="privacy statement text" />
                                            </div>
                                            <ErrorMessage name="opt_in_settings_security_text" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Security Icon Color <a class="reset-default" @click="resetToDefault('opt_in_settings_pop_up_security_icon_color_form')">Reset to default</a></label>
                                            <Field autocomplete="off" name="opt_in_settings_pop_up_security_icon_color" v-model="opt_in_settings_pop_up_security_icon_color_form.setting_value" type="text" label="security icon color">
                                                <color-picker v-model="opt_in_settings_pop_up_security_icon_color_form.setting_value" :classes="{ 'has-error': errors.opt_in_settings_pop_up_security_icon_color }" />
                                            </Field>
                                            <ErrorMessage name="opt_in_settings_pop_up_security_icon_color" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <h3 class="sub_header mt-5">Confirmation Page</h3>
                                <div class="edit_section">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label" for="thankyou_title">
                                                    Headline Text
                                                    <div class="flex-center">
                                                        <button type="button" class="reset-default mr-1" @click="resetToDefault('opt_in_settings_confirmation_page_headline_text_form')">Reset to default</button>
                                                        <div class="dropdown mr-1" @click="thankTitlePersonalizeOption = !thankTitlePersonalizeOption">
                                                            <i class="fas fa-user-alt"></i>
                                                            <div class="drp_wrapper" v-if="thankTitlePersonalizeOption" :class="{ active : thankTitlePersonalizeOption }">
                                                                <ul>
                                                                    <li v-for="(personalize, p) of defaultPersonalize" :key="p" @click="insertPersonalize(personalize, 'thankyou_title', 'opt_in_settings_confirmation_page_headline_text_form')">{{ personalize }}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.opt_in_settings_confirmation_page_headline_text }">
                                                    <Field autocomplete="off" type="text" name="opt_in_settings_confirmation_page_headline_text" id="thankyou_title"  v-model="opt_in_settings_confirmation_page_headline_text_form.setting_value" @focusout="handleAutosave(opt_in_settings_confirmation_page_headline_text_form, 'opt_in_settings_confirmation_page_headline_text_form')" rules="required" label="headline text" />
                                                </div>
                                                <ErrorMessage name="opt_in_settings_confirmation_page_headline_text" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Thank You Message <button type="button" class="reset-default mr-1" @click="resetToDefault('opt_in_settings_confirmation_page_message_form')">Reset to default</button></label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.opt_in_settings_confirmation_page_message }">
                                                    <Field autocomplete="off" name="opt_in_settings_confirmation_page_message" v-model="opt_in_settings_confirmation_page_message_form.setting_value" label="message" rules="required">
                                                        <redactor v-model="opt_in_settings_confirmation_page_message_form.setting_value" name="opt_in_settings_confirmation_page_message" :default-value="thankyouMessageDefaultValue" ref="step-editor" :default-value-watcher="thankyouMessageDefaultValueWatcher" :email-editor="false" :track-focusout="trackThankyouMessageFocusout" />
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="opt_in_settings_confirmation_page_message" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">CTA Button Text <a class="reset-default" @click="resetToDefault('opt_in_settings_confirmation_page_cta_button_text_form')">Reset to default</a></label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.opt_in_settings_confirmation_page_cta_button_text }">
                                                    <Field autocomplete="off" type="text" name="opt_in_settings_confirmation_page_cta_button_text" v-model="opt_in_settings_confirmation_page_cta_button_text_form.setting_value" @focusout="handleAutosave(opt_in_settings_confirmation_page_cta_button_text_form, 'opt_in_settings_confirmation_page_cta_button_text_form')" placeholder="Go To Home" rules="required" label="cta button text" :disabled="true" />
                                                </div>
                                                <ErrorMessage name="opt_in_settings_confirmation_page_cta_button_text" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 class="sub_header mt-5">
                                    Tagging
                                    <label for="has_tagging" class="switch_option capsule_btn ml-auto">
                                        <input type="checkbox" id="has_tagging" :true-value="true" :false-value="false" v-model="opt_in_settings_tagging_enabled_form.setting_value" @change="handleAutosave(opt_in_settings_tagging_enabled_form, 'opt_in_settings_tagging_enabled_form')" hidden>
                                        <div><span></span></div>
                                    </label>
                                </h3>
                                <div class="edit_section" v-if="opt_in_settings_tagging_enabled_form.setting_value">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">
                                                Select Tags
                                                <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                            </label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.opt_in_settings_tagging_value }">
                                                <Field autocomplete="off" name="opt_in_settings_tagging_value" v-model="opt_in_settings_tagging_value_form.setting_value" label="tag">
                                                    <multiselect
                                                        v-model="opt_in_settings_tagging_value_form.setting_value"
                                                        mode="tags"
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        :group-select="true"
                                                        :options="tagsGroup"
                                                        :groups="true"
                                                        group-label="type"
                                                        group-options="tags"
                                                        :group-hide-empty="true"
                                                        placeholder="Select Tags"
                                                        @select="handleAutosave(opt_in_settings_tagging_value_form, 'opt_in_settings_tagging_value_form')"
                                                        @deselect="handleAutosave(opt_in_settings_tagging_value_form, 'opt_in_settings_tagging_value_form')"
                                                    >
                                                        <template v-slot:grouplabel="{ group }">
                                                            <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                        </template>
                                                        <template v-slot:option="{ option }">
                                                            <span>{{ option.name }}</span>
                                                        </template>
                                                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                            <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                {{ option.name }}
                                                                <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                                                    <span class="multiselect-tag-remove-icon"></span>
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="opt_in_settings_tagging_value" class="text-danger" />
                                        </div>
                                    </div>
                                    <div v-if="createTags" class="form_grp">
                                        <Form v-slot="{ errors }" ref="tag-form">
                                            <div class="group_item">
                                                <label class="input_label">Create a New Tag</label>
                                                <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                    <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                                    <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                                </div>
                                                <ErrorMessage name="name" class="text-danger" />
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <h3 class="sub_header mt-5">
                                    Nurture
                                    <label for="opt_in_settings_nurture_enabled" class="switch_option capsule_btn ml-auto">
                                        <input type="checkbox" id="opt_in_settings_nurture_enabled" :true-value="true" :false-value="false" v-model="opt_in_settings_nurture_enabled_form.setting_value" @change="handleAutosave(opt_in_settings_nurture_enabled_form, 'opt_in_settings_nurture_enabled_form')" hidden>
                                        <div><span></span></div>
                                    </label>
                                </h3>
                                <div class="edit_section" v-if="opt_in_settings_nurture_enabled_form.setting_value">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Start Sequence</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.opt_in_settings_nurture_value }">
                                                <Field autocomplete="off" name="opt_in_settings_nurture_value" v-model="opt_in_settings_nurture_value_form.setting_value" label="sequence">
                                                    <multiselect
                                                        v-model="opt_in_settings_nurture_value_form.setting_value"
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        :options="sequences"
                                                        placeholder="Select Sequence"
                                                        mode="tags"
                                                        @select="handleAutosave(opt_in_settings_nurture_value_form, 'opt_in_settings_nurture_value_form')"
                                                        @deselect="handleAutosave(opt_in_settings_nurture_value_form, 'opt_in_settings_nurture_value_form')"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="opt_in_settings_nurture_value" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <h3 class="sub_header mt-5">
                                    Notifications
                                    <label for="opt_in_settings_notification_enabled" class="switch_option capsule_btn ml-auto">
                                        <input type="checkbox" id="opt_in_settings_notification_enabled" :true-value="true" :false-value="false" v-model="opt_in_settings_notification_enabled_form.setting_value" @change="handleAutosave(opt_in_settings_notification_enabled_form, 'opt_in_settings_notification_enabled_form')" hidden>
                                        <div><span></span></div>
                                    </label>
                                </h3>
                                <template v-if="opt_in_settings_notification_enabled_form.setting_value">
                                    <div class="edit_section mb-3">
                                        <sending-method v-model="opt_in_settings_notification_sending_method_form.setting_value" />
                                    </div>
                                    <div v-if="opt_in_settings_notification_sending_method_form.setting_value === 1 || opt_in_settings_notification_sending_method_form.setting_value === 3">
                                        <div class="setting_wpr mb-3">
                                            <email-component v-model="notificationEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleDefaultEmail" preview-module="page" ref="email-component" :track-focusout="trackNotificationEmailFocusout" />
                                        </div>
                                    </div>
                                    <div v-if="opt_in_settings_notification_sending_method_form.setting_value === 2 || opt_in_settings_notification_sending_method_form.setting_value === 3">
                                        <div class="setting_wpr">
                                            <sms-component v-model="opt_in_settings_notification_sms_message_form.setting_value" :default-sms-handler="true" :handle-default-sms="handleDefaultSms" :media="notificationSmsMedia" mediaFieldName="notificationSmsMedia" preview-module="playbook" :update-sms-text="updateSmsText()" ref="sms-component" :update-has-media-field="updateNotificationHasMediaField" :track-focusout="trackNotificationSMSFocusout" />
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-if="tab == 2">
                                <h3 class="sub_header mt-1">Social Share</h3>
                                <div class="edit_section">
                                    <div class="group_item p-0">
                                        <label class="input_label mb-2">Image</label>
                                    </div>
                                    <image-library v-model="opt_in_settings_social_sharing_image_form.setting_value" image-type="social-share" />
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Title</label>
                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.opt_in_settings_social_sharing_title }">
                                                <Field autocomplete="off" type="text" name="opt_in_settings_social_sharing_title" placeholder="ex: New Title" v-model="opt_in_settings_social_sharing_title_form.setting_value" @focusout="handleAutosave(opt_in_settings_social_sharing_title_form, 'opt_in_settings_social_sharing_title_form')" rules="required" label="social share title" />
                                            </div>
                                            <ErrorMessage name="opt_in_settings_social_sharing_title" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Description</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.opt_in_settings_social_sharing_description }">
                                                <Field autocomplete="off" type="textarea" name="opt_in_settings_social_sharing_description" v-model="opt_in_settings_social_sharing_description_form.setting_value">
                                                    <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="opt_in_settings_social_sharing_description_form.setting_value" @focusout="handleAutosave(opt_in_settings_social_sharing_description_form, 'opt_in_settings_social_sharing_description_form')"></textarea>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="opt_in_settings_social_sharing_description" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="setting_wpr" v-if="settingType === 'Footer'">
                            <h3 class="sub_header mt-1">Copyright</h3>
                            <div class="edit_section">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Text</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.footer_settings_copyright_text }">
                                            <Field autocomplete="off" type="text" name="footer_settings_copyright_text" v-model="footer_settings_copyright_text_form.setting_value" @focusout="handleAutosave(footer_settings_copyright_text_form, 'footer_settings_copyright_text_form')" placeholder="ex. Company Name. All rights reserved" rules="required" label="copyright text" />
                                        </div>
                                        <ErrorMessage name="footer_settings_copyright_text" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <h3 class="sub_header mt-5">
                                Privacy Policy
                                <label for="security" class="switch_option capsule_btn p-0">
                                    <input type="checkbox" id="security" :true-value="true" :false-value="false" v-model="footer_settings_privacy_policy_enabled_form.setting_value" @change="handleAutosave(footer_settings_privacy_policy_enabled_form, 'footer_settings_privacy_policy_enabled_form')" hidden>
                                    <div><span></span></div>
                                </label>
                            </h3>
                            <div class="edit_section" v-if="footer_settings_privacy_policy_enabled_form.setting_value">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Text</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.footer_settings_privacy_policy_text }">
                                            <Field autocomplete="off" type="text" name="footer_settings_privacy_policy_text" v-model="footer_settings_privacy_policy_text_form.setting_value" @focusout="handleAutosave(footer_settings_privacy_policy_text_form, 'footer_settings_privacy_policy_text_form')" placeholder="ex. Privacy Policy" rules="required" label="privacy policy text" />
                                        </div>
                                        <ErrorMessage name="footer_settings_privacy_policy_text" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Privacy Policy Link</label>
                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.footer_settings_privacy_policy_link }">
                                            <Field autocomplete="off" type="text" name="footer_settings_privacy_policy_link" v-model="footer_settings_privacy_policy_link_form.setting_value" @focusout="handleAutosave(footer_settings_privacy_policy_link_form, 'footer_settings_privacy_policy_link_form')" placeholder="ex. yoursite.com/privacy-policy" rules="required" label="privacy policy link" />
                                            <span class="prefix">https://</span>
                                        </div>
                                        <ErrorMessage name="footer_settings_privacy_policy_link" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal_footer">
                        <button type="button" class="btn cancel_btn" @click="guideForm = false;">Close</button>
                        <!-- <button v-if="settingType === 'Benefit'" @click="handleUpdateBenefitSettings()" type="button" :disabled="guideFormLoaderStatus" class="btn save_btn"><i v-if="guideFormLoaderStatus" class="fa fa-spin fa-spinner"></i> {{ guideFormLoaderStatus ? 'Saving' : 'Save' }}</button>
                        <button v-else-if="settingType === 'Media'" @click="handleUpdateMediaSettings()" type="button" :disabled="guideFormLoaderStatus" class="btn save_btn"><i v-if="guideFormLoaderStatus" class="fa fa-spin fa-spinner"></i> {{ guideFormLoaderStatus ? 'Saving' : 'Save' }}</button>
                        <button v-else-if="settingType === 'Opt-In'" @click="handleUpdateOptinSettings()" type="button" :disabled="optinFormLoaderStatus" class="btn save_btn"><i v-if="optinFormLoaderStatus" class="fa fa-spin fa-spinner"></i> {{ optinFormLoaderStatus ? 'Saving' : 'Save' }}</button>
                        <button v-else-if="settingType === 'Footer'" @click="handleUpdateFooterSettings()" type="button" :disabled="footerFormLoaderStatus" class="btn save_btn"><i v-if="footerFormLoaderStatus" class="fa fa-spin fa-spinner"></i> {{ footerFormLoaderStatus ? 'Saving' : 'Save' }}</button>
                        <button v-else type="button" :disabled="guideFormLoaderStatus" class="btn save_btn">Save</button> -->
                    </div>
                </Form>
            </div>
        </div>
        <div class="modal video_player" v-if="showVideo" :class="{ active : showVideo }" @click.self="showVideo = false">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="showVideo = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal_body p-0" style="margin-right: -4px;">
                    <div class="video_wpr" @click="showVideo = true" v-if="(media_setting_type_form.setting_value == 'link' && media_setting_video_link_value_from.setting_value) || (media_setting_type_form.setting_value == 'embed' && media_setting_embedded_video_value_form.setting_value)">
                        <div v-if="media_setting_type_form.setting_value == 'link' && media_setting_video_link_value_from.setting_value" v-html="parseEmbedCode(media_setting_video_link_value_from.setting_value)"></div>
                        <div v-else-if="media_setting_type_form.setting_value == 'embed' && media_setting_embedded_video_value_form.setting_value" v-html="media_setting_embedded_video_value_form.setting_value"></div>
                    </div>
                </div>
            </div>
        </div>
        <optin-preview :playbook="playbook" v-model="fullPreview" />
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'

const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))
const OptinPreview = defineAsyncComponent(() => import('@/pages/playbook/components/OptinPreview'))
const NewsletterIcon = defineAsyncComponent(() => import('@/pages/optin/components/Icon'))
const SmsComponent = defineAsyncComponent(() => import('@/components/SmsComponent'))
const EmailComponent = defineAsyncComponent(() => import('@/components/EmailComponent'))
const SendingMethod = defineAsyncComponent(() => import('@/components/SendingMethod'))

import constants from '@/utils/Constant'
import VideoParser from '@/utils/VideoParser'
import Toastr from '@/utils/Toastr'
import debounce from 'lodash.debounce'

export default {
    name: 'Playbook Optin',

    data () {
        return{
            bulletIcons: ['check', 'check-circle', 'bullet', 'chevron', 'chevron-circle', 'heart', 'triangle', 'kettlebell'],
            createTags: false,
            tagForm: {
                name: '',
                type: 'leads',
                uniqueId: 'optin',
            },
            guideForm: false,
            settingType: '',
            gdprPersonalizeOption: false,
            thankTitlePersonalizeOption: false,
            defaultPersonalize: [],
            fullPreview: false,
            showVideo: false,
            tab: 1,
            notificationEmail: {
                defaultHandler: true,
                subject: '🔥[New Lead] {{playbook_name}} funnel',
                email: '<p>Hi {{first_name}},</p><p>Your opt-in page is converting!</p><p></p><p>{{first_name}} {{last_name}} {{email}}</p><p></p><p>Opted into your {{playbook_name}} lead funnel.</p><p></p><p>Send them a welcome message in thier client portal!</p><p></p><p>Nice work!</p><p>Team {{company_name}}</p>',
                is_email_logo: 0,
                is_signature: 0,
                signature_id: 0,
                show_email_logo: 0,
                email_logo: '',
            },
            notificationSmsMedia: [],
            benefits_form: {
                id: 0,
                setting_value: [
                    'Get Instant Access For Life!',
                    'Delivered Securely',
                    'Stay on Track WIth Weight Loss',
                    'More Than 20 Healthy Options!'
                ],
            },
            benefit_setting_bullet_icon_color_form: {
                id: 0,
                setting_value: '#2F7EED'
            },
            benefit_setting_bullet_icon_size_form: {
                id: 0,
                setting_value: 20
            },
            benefit_setting_bullet_icon_type_form: {
                id: 0,
                setting_value: 'check'
            },
            benefit_setting_bullet_text_color_form: {
                id: 0,
                setting_value: '#121525'
            },
            company_name_form: {
                id: 0,
                setting_value: 'Thrive Coach'
            },
            footer_settings_copyright_text_form: {
                id: 0,
                setting_value: 'Thrive Coach. All rights reserved'
            },
            footer_settings_privacy_policy_enabled_form: {
                id: 0,
                setting_value: true
            },
            footer_settings_privacy_policy_link_form: {
                id: 0,
                setting_value: 'https://thrivecoach.io/privacy-policy'
            },
            footer_settings_privacy_policy_text_form: {
                id: 0,
                setting_value: 'Privacy Policy'
            },
            headline_form: {
                id: 0,
                setting_value: 'Get Your Free Dining Out Guide!'
            },
            media_setting_type_form: {
                id: 0,
                setting_value: 'image'
            },
            media_setting_image_value_form: {
                id: 0,
                setting_value: 'https://storage.googleapis.com/onboardme/uploads/media/33255/1280X720/om-image-1718360916-4817122-33255.png'
            },
            media_setting_embedded_video_value_form: {
                id: 0,
                setting_value: ''
            },
            media_setting_video_link_value_form: {
                id: 0,
                setting_value: ''
            },
            opt_in_settings_button_color_form: {
                id: 0,
                setting_value: '#2F7EED'
            },
            opt_in_settings_button_text_form: {
                id: 0,
                setting_value: 'Send Me The Guide!'
            },
            opt_in_settings_button_text_color_form: {
                id: 0,
                setting_value: '#FFF'
            },
            opt_in_settings_confirmation_page_cta_button_text_form: {
                id: 0,
                setting_value: 'Go To Portal'
            },
            opt_in_settings_confirmation_page_headline_text_form: {
                id: 0,
                setting_value: 'Thank You, {{first_name}}!'
            },
            opt_in_settings_confirmation_page_message_form: {
                id: 0,
                setting_value: `<p><span style="text-align: initial;">You're all set! Check your inbox for an email from us within the next 5-10 minutes. It will contain access details for your new client portal and training materials. If you encounter any issues accessing your portal or have any questions, please contact us at {{support_email}}.</span><br></p>
                                <p></p>
                                <p>Have an amazing day! And remember, you can always reach out to us through your portal chat for any training-related questions.</p>
                                <p></p>
                                <p></p>
                                <p>Keep Thriving!</p>
                                <p></p>
                                <p>{{company_name}}</p>
                                <p>{{company_website}}</p>`
            },
            opt_in_settings_notification_email_message_form: {
                id: 0,
                setting_value: 'Hi {{first_name}},\n\n Your opt-in page is converting!\n\n {{first_name}} {{last_name}} {{email}}\n\n Opted into your {{playbook_name}} lead funnel.\n\n Send them a welcome message in thier client portal!\n\n Nice work!\n\n Team {{company_name}}'
            },
            opt_in_settings_notification_email_subject_form: {
                id: 0,
                setting_value: '🔥[New Lead] {{playbook_name}} funnel'
            },
            opt_in_settings_notification_enabled_form: {
                id: 0,
                setting_value: true
            },
            opt_in_settings_notification_sending_method_form: {
                id: 0,
                setting_value: 1
            },
            opt_in_settings_notification_sms_attachment_enabled_form: {
                id: 0,
                setting_value: false
            },
            opt_in_settings_notification_sms_attachment_value_form: {
                id: 0,
                setting_value: ''
            },
            opt_in_settings_notification_sms_message_form: {
                id: 0,
                setting_value: 'Hi {{first_name}},\n Your opt-in page is converting!\n {{first_name}} {{last_name}} {{email}}\n Opted into your {{playbook_name}} lead funnel.\n Send them a welcome message in thier client portal!\n Nice work!\n Team {{company_name}}'
            },
            opt_in_settings_nurture_enabled_form: {
                id: 0,
                setting_value: false
            },
            opt_in_settings_nurture_value_form: {
                id: 0,
                setting_value: [2, 3, 4]
            },
            opt_in_settings_pop_up_gdpr_permission_text_form: {
                id: 0,
                setting_value: 'I agree to receive emails and text messages from {{company_name}}'
            },
            opt_in_settings_pop_up_image_form: {
                id: 0,
                setting_value: 'https://storage.googleapis.com/onboardme/uploads/media/33255/1280X720/om-image-1718360916-4817122-33255.png'
            },
            opt_in_settings_pop_up_image_enabled_form: {
                id: 0,
                setting_value: true
            },
            opt_in_settings_pop_up_opt_gdpr_permission_enabled_form: {
                id: 0,
                setting_value: true
            },
            opt_in_settings_pop_up_opt_in_fields_email_enabled_form: {
                id: 0,
                setting_value: true
            },
            opt_in_settings_pop_up_opt_in_fields_first_name_enabled_form: {
                id: 0,
                setting_value: true
            },
            opt_in_settings_pop_up_opt_in_fields_last_name_enabled_form: {
                id: 0,
                setting_value: true
            },
            opt_in_settings_pop_up_opt_in_fields_phone_enabled_form: {
                id: 0,
                setting_value: false
            },
            opt_in_settings_pop_up_opt_in_headline_form: {
                id: 0,
                setting_value: 'Where Should We Send Your Guide?'
            },
            opt_in_settings_pop_up_privacy_statement_text_form: {
                id: 0,
                setting_value: 'Delivered securely. Your information is safe.'
            },
            opt_in_settings_pop_up_security_icon_color_form: {
                id: 0,
                setting_value: '#2F7EED'
            },
            opt_in_settings_pop_up_submit_button_text_form: {
                id: 0,
                setting_value: 'Send Me The Guide!'
            },
            opt_in_settings_security_text_form: {
                id: 0,
                setting_value: 'Delivered securely. Your information is safe.'
            },
            opt_in_settings_social_sharing_description_form: {
                id: 0,
                setting_value: 'Enjoy Delicious Macros-Friendly Meals Without the Guilt'
            },
            opt_in_settings_social_sharing_image_form: {
                id: 0,
                setting_value: ''
            },
            opt_in_settings_social_sharing_title_form: {
                id: 0,
                setting_value: 'Get Your Free Dining Out Guide!'
            },
            opt_in_settings_tagging_enabled_form: {
                id: 0,
                setting_value: false
            },
            opt_in_settings_tagging_value_form: {
                id: 0,
                setting_value: [2, 3, 4]
            },
            sub_headline_form: {
                id: 0,
                setting_value: 'Enjoy Delicious Macros-Friendly Meals Without the Guilt'
            },
            guideFormLoaderStatus: false,
            notificationValuesFilled: false,
            mounted: false,
            thankyouMessageDefaultValueWatcher: 0,
            thankyouMessageDefaultValue: 'Thank You Message...',
            tutorialVideo: '',
            tutorial: false,
            tutorialList: false,
        }
    },

    components:{
        Form,
        Field,
        ErrorMessage,
        ImageLibrary,
        OptinPreview,
        NewsletterIcon,
        SmsComponent,
        EmailComponent,
        SendingMethod,
    },

    props: {
        modelValue: Boolean,
        playbook: Object,
        refreshPlaybook: Function,
    },

    emits: ['update:modelValue'],

    watch:{
        modelValue (val) {
            const vm = this;

            vm.mounted = false;

            if (val) {
                document.body.classList.add('modal-open');

                if (vm.playbook && vm.playbook.id) {
                    vm.getOptinSettings({ id: vm.playbook.id, service_name: 'playbook' }).then((result) => {
                        if (!vm.playbook.opt_in_url && vm.refreshPlaybook) {
                            vm.refreshPlaybook(vm.playbook.id);
                        }
                    });
                }

                if (vm.tagsGroup.length == 0) {
                    vm.getTags();
                }

                if (vm.sequences.length == 0) {
                    vm.getAllSequences();
                }

                setTimeout(() => {
                    vm.mounted = true;
                }, 2000)
            } else {
                document.body.classList.remove('modal-open');
            }
        },

        optinSettings: {
            handler (optinSettings) {
                const vm = this;

                vm.resetForm(optinSettings);
            },
            deep: true
        },

        'notificationEmail.email' (value) {
            const vm = this;

            if (vm.notificationValuesFilled) {
                vm.opt_in_settings_notification_email_message_form.setting_value = value;
            }
        },

        'notificationEmail.subject' (value) {
            const vm = this;

            if (vm.notificationValuesFilled) {
                vm.opt_in_settings_notification_email_subject_form.setting_value = value;
            }
        },

        notificationSmsMedia (value) {
            const vm = this;

            if (vm.notificationValuesFilled) {
                vm.opt_in_settings_notification_sms_attachment_value_form.setting_value = value[0];
            }
        },

        'media_setting_image_value_form.setting_value' (value) {
            const vm = this;

            vm.handleAutosave(vm.media_setting_image_value_form, 'media_setting_image_value_form');
        },

        'benefit_setting_bullet_icon_color_form.setting_value': {
            handler: debounce(function (value) {
                const vm = this;

                vm.handleAutosave(vm.benefit_setting_bullet_icon_color_form, 'benefit_setting_bullet_icon_color_form');
            }, 1000),
            immediate: true
        },

        'benefit_setting_bullet_text_color_form.setting_value': {
            handler: debounce(function (value) {
                const vm = this;

                vm.handleAutosave(vm.benefit_setting_bullet_text_color_form, 'benefit_setting_bullet_text_color_form');
            }, 1000),
            immediate: true
        },

        'opt_in_settings_button_color_form.setting_value': {
            handler: debounce(function (value) {
                const vm = this;

                vm.handleAutosave(vm.opt_in_settings_button_color_form, 'opt_in_settings_button_color_form');
            }, 1000),
            immediate: true
        },

        'opt_in_settings_button_text_color_form.setting_value': {
            handler: debounce(function (value) {
                const vm = this;

                vm.handleAutosave(vm.opt_in_settings_button_text_color_form, 'opt_in_settings_button_text_color_form');
            }, 1000),
            immediate: true
        },

        'opt_in_settings_pop_up_security_icon_color_form.setting_value': {
            handler: debounce(function (value) {
                const vm = this;

                vm.handleAutosave(vm.opt_in_settings_pop_up_security_icon_color_form, 'opt_in_settings_pop_up_security_icon_color_form');
            }, 1000),
            immediate: true
        },

        'opt_in_settings_pop_up_image_form.setting_value' (value) {
            const vm = this;

            vm.handleAutosave(vm.opt_in_settings_pop_up_image_form, 'opt_in_settings_pop_up_image_form');
        },

        'opt_in_settings_notification_sending_method_form.setting_value' (value) {
            const vm = this;

            vm.handleAutosave(vm.opt_in_settings_notification_sending_method_form, 'opt_in_settings_notification_sending_method_form');
        },

        'opt_in_settings_social_sharing_image_form.setting_value' (value) {
            const vm = this;

            vm.handleAutosave(vm.opt_in_settings_social_sharing_image_form, 'opt_in_settings_social_sharing_image_form');
        },

        guideForm (val) {
            const vm = this;

            if (val) {
                vm.mounted = false;

                if (vm.settingType == 'Opt-In') {
                    setTimeout(() => {
                        vm.thankyouMessageDefaultValue = vm.opt_in_settings_confirmation_page_message_form.setting_value;

                        vm.thankyouMessageDefaultValueWatcher += 1;
                    }, 1000)
                }

                setTimeout(() => {
                    vm.mounted = true;
                }, 1000)
            }
        },
    },

    mounted () {
        const vm = this;

        vm.defaultPersonalize = constants.personalize.concat(vm.personalize);
        vm.env = process.env;
    },

    computed: mapState({
        tagsGroup: state => state.tagModule.tagsGroup,
        sequences: state => state.sequenceModule.allSequences,
        optinSettings: state => state.optinsModule.optinSettings,
        optinSettingsLoaderSatatus: state => state.optinsModule.optinSettingsLoaderSatatus,
        optinSettingsUpdateLoaderSatatus: state => state.optinsModule.optinSettingsUpdateLoaderSatatus,
    }),

    methods: {
        ...mapActions({
            getTags: 'tagModule/getTags',
            createTag: 'tagModule/createTag',
            getAllSequences: 'sequenceModule/getAllSequences',
            startSequence: 'sequenceModule/startSequence',
            getOptinSettings: 'optinsModule/getOptinSettings',
            updateOptinSettings: 'optinsModule/updateOptinSettings',
        }),

        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },

        resetForm (optinSettings) {
            const vm = this;

            vm.notificationValuesFilled = false;

            Object.values(optinSettings).forEach((setting) => {
                const key = `${setting.setting_name}_form`;

                vm[key] = { id: setting.id, setting_value: setting.setting_value };
            });

            vm.fillNotificationEmailValues();

            vm.thankyouMessageDefaultValueWatcher += 1;
        },

        handleAutosave (data, key = '') {
            const vm = this;
            const form = vm.$refs['playbook-optin-form'];

            if (data.id && vm.mounted) {
                data.setFieldError   = form.setFieldError;
                data.field           = key;

                vm.updateOptinSettings(data).then((result) => {
                    if (result) {
                        Toastr.success('Optin settings has been saved successfully');
                        vm.getOptinSettings({ id: vm.playbook.id, service_name: 'playbook' });
                    }
                });
            }
        },

        handleInlineEdit (event, className, data = {}, key = '') {
            const vm = this;
            const element = event.currentTarget.closest(className);
            const form = vm.$refs['playbook-optin-form'];

            if (element.classList.contains('rename')) {
                if (data.id && vm.mounted) {
                    data.setFieldError   = form.setFieldError;
                    data.field           = key;

                    vm.updateOptinSettings(data).then((result) => {
                        if (result) {
                            Toastr.success('Optin settings has been saved successfully');
                            vm.getOptinSettings({ id: vm.playbook.id, service_name: 'playbook' });
                        }
                    });
                }

                element.classList.remove('rename');
            } else {
                element.classList.add('rename');
            }
        },

        handleUpdateBenefitSettings () {
            const vm = this;
            const benifits = [
                'benefit_setting_bullet_icon_color_form',
                'benefit_setting_bullet_icon_size_form',
                'benefit_setting_bullet_icon_type_form',
                'benefit_setting_bullet_text_color_form',
            ];
            const form = vm.$refs['playbook-optin-form'];

            benifits.forEach((key) => {
                vm.guideFormLoaderStatus = true;

                vm[key].setFieldError   = form.setFieldError;
                vm[key].field           = key.replaceAll('_form', '');

                vm.updateOptinSettings(vm[key]).then((result) => {
                    if (result) {
                        if (key == 'benefit_setting_bullet_text_color_form') {
                            // vm.getOptinSettings({ id: vm.playbook.id, service_name: 'playbook' });

                            Toastr.success('Optin settings has been saved successfully');

                            vm.guideFormLoaderStatus = false;
                            vm.guideForm = false;
                        }
                    } else {
                        if (key == 'benefit_setting_bullet_text_color_form') {
                            vm.guideFormLoaderStatus = false;
                        }
                    }
                });
            });
        },

        handleUpdateMediaSettings () {
            const vm = this;
            const benifits = [
                'media_setting_type_form',
                'media_setting_image_value_form',
                'media_setting_embedded_video_value_form',
                'media_setting_video_link_value_form',
            ];
            const form = vm.$refs['playbook-optin-form'];

            benifits.forEach((key) => {
                vm.guideFormLoaderStatus = true;

                vm[key].setFieldError   = form.setFieldError;
                vm[key].field           = key.replaceAll('_form', '');

                vm.updateOptinSettings(vm[key]).then((result) => {
                    if (result) {
                        if (key == 'media_setting_video_link_value_form') {
                            // vm.getOptinSettings({ id: vm.playbook.id, service_name: 'playbook' });

                            Toastr.success('Optin settings has been saved successfully');

                            vm.guideFormLoaderStatus = false;
                            vm.guideForm = false;
                        }
                    } else {
                        if (key == 'media_setting_video_link_value_form') {
                            vm.guideFormLoaderStatus = false;
                        }
                    }
                });
            });
        },

        handleUpdateOptinSettings () {
            const vm = this;
            const benifits = [
                'opt_in_settings_button_color_form',
                'opt_in_settings_button_text_form',
                'opt_in_settings_button_text_color_form',
                'opt_in_settings_confirmation_page_cta_button_text_form',
                'opt_in_settings_confirmation_page_headline_text_form',
                'opt_in_settings_confirmation_page_message_form',
                'opt_in_settings_notification_email_message_form',
                'opt_in_settings_notification_email_subject_form',
                'opt_in_settings_notification_enabled_form',
                'opt_in_settings_notification_sending_method_form',
                'opt_in_settings_notification_sms_attachment_enabled_form',
                'opt_in_settings_notification_sms_attachment_value_form',
                'opt_in_settings_notification_sms_message_form',
                'opt_in_settings_nurture_enabled_form',
                'opt_in_settings_nurture_value_form',
                'opt_in_settings_pop_up_gdpr_permission_text_form',
                'opt_in_settings_pop_up_image_form',
                'opt_in_settings_pop_up_image_enabled_form',
                'opt_in_settings_pop_up_opt_gdpr_permission_enabled_form',
                'opt_in_settings_pop_up_opt_in_fields_email_enabled_form',
                'opt_in_settings_pop_up_opt_in_fields_first_name_enabled_form',
                'opt_in_settings_pop_up_opt_in_fields_last_name_enabled_form',
                'opt_in_settings_pop_up_opt_in_fields_phone_enabled_form',
                'opt_in_settings_pop_up_opt_in_headline_form',
                'opt_in_settings_pop_up_privacy_statement_text_form',
                'opt_in_settings_pop_up_security_icon_color_form',
                'opt_in_settings_pop_up_submit_button_text_form',
                'opt_in_settings_security_text_form',
                'opt_in_settings_social_sharing_description_form',
                'opt_in_settings_social_sharing_image_form',
                'opt_in_settings_social_sharing_title_form',
                'opt_in_settings_tagging_enabled_form',
                'opt_in_settings_tagging_value_form',
            ];
            const form = vm.$refs['playbook-optin-form'];

            benifits.forEach((key) => {
                vm.optinFormLoaderStatus = true;

                vm[key].setFieldError   = form.setFieldError;
                vm[key].field           = key.replaceAll('_form', '');

                vm.updateOptinSettings(vm[key]).then((result) => {
                    if (result) {
                        if (key == '') {
                            // vm.getOptinSettings({ id: vm.playbook.id, service_name: 'playbook' });

                            Toastr.success('Optin settings has been saved successfully');

                            vm.optinFormLoaderStatus = false;
                            vm.guideForm = false;
                        }
                    } else {
                        if (key == '') {
                            vm.optinFormLoaderStatus = false;
                        }
                    }
                });
            });
        },

        handleUpdateFooterSettings () {
            const vm = this;
            const benifits = [
                'footer_settings_copyright_text_form',
                'footer_settings_privacy_policy_enabled_form',
                'footer_settings_privacy_policy_text_form',
                'footer_settings_privacy_policy_link_form',
            ];
            const form = vm.$refs['playbook-optin-form'];

            benifits.forEach((key) => {
                vm.footerFormLoaderStatus = true;

                vm[key].setFieldError   = form.setFieldError;
                vm[key].field           = key.replaceAll('_form', '');

                vm.updateOptinSettings(vm[key]).then((result) => {
                    if (result) {
                        if (key == 'footer_settings_privacy_policy_link_form') {
                            // vm.getOptinSettings({ id: vm.playbook.id, service_name: 'playbook' });

                            Toastr.success('Optin settings has been saved successfully');

                            vm.footerFormLoaderStatus = false;
                            vm.guideForm = false;
                        }
                    } else {
                        if (key == 'footer_settings_privacy_policy_link_form') {
                            vm.footerFormLoaderStatus = false;
                        }
                    }
                });
            });
        },

        handleModal (type) {
            const vm = this;

            vm.settingType = type;
            vm.guideForm = true;
        },

        resetToDefault (key) {
            const vm = this;
            const defaultValues = {
                benefits_form: [
                    'Get Instant Access For Life!',
                    'Delivered Securely',
                    'Stay on Track WIth Weight Loss',
                    'More Than 20 Healthy Options!'
                ],
                benefit_setting_bullet_icon_color_form: '#2F7EED',
                benefit_setting_bullet_icon_size_form: 20,
                benefit_setting_bullet_icon_type_form: 'check',
                benefit_setting_bullet_text_color_form: '#121525',
                company_name_form: 'Thrive Coach',
                footer_settings_copyright_text_form: 'Thrive Coach. All rights reserved',
                footer_settings_privacy_policy_enabled_form: true,
                footer_settings_privacy_policy_link_form: 'https://thrivecoach.io/privacy-policy',
                footer_settings_privacy_policy_text_form: 'Privacy Policy',
                headline_form: 'Get Your Free Dining Out Guide!',
                media_setting_type_form: 'image',
                media_setting_image_value_form: 'https://storage.googleapis.com/onboardme/uploads/media/33255/1280X720/om-image-1718360916-4817122-33255.png',
                media_setting_embedded_video_value_form: '',
                media_setting_video_link_value_form: '',
                opt_in_settings_button_color_form: '#2F7EED',
                opt_in_settings_button_text_form: 'Send Me The Guide!',
                opt_in_settings_button_text_color_form: '#FFF',
                opt_in_settings_confirmation_page_cta_button_text_form: 'Go To Portal',
                opt_in_settings_confirmation_page_headline_text_form: 'Thank You, {{first_name}}!',
                opt_in_settings_confirmation_page_message_form: `<p><span style="text-align: initial;">You're all set! Check your inbox for an email from us within the next 5-10 minutes. It will contain access details for your new client portal and training materials. If you encounter any issues accessing your portal or have any questions, please contact us at {{support_email}}.</span><br></p>
                                                                <p></p>
                                                                <p>Have an amazing day! And remember, you can always reach out to us through your portal chat for any training-related questions.</p>
                                                                <p></p>
                                                                <p></p>
                                                                <p>Keep Thriving!</p>
                                                                <p></p>
                                                                <p>{{company_name}}</p>
                                                                <p>{{company_website}}</p>`,
                opt_in_settings_notification_email_message_form: 'Hi {{first_name}},\n\n Your opt-in page is converting!\n\n {{first_name}} {{last_name}} {{email}}\n\n Opted into your {{playbook_name}} lead funnel.\n\n Send them a welcome message in thier client portal!\n\n Nice work!\n\n Team {{company_name}}',
                opt_in_settings_notification_email_subject_form: '🔥[New Lead] {{playbook_name}} funnel',
                opt_in_settings_notification_enabled_form: true,
                opt_in_settings_notification_sending_method_form: 1,
                opt_in_settings_notification_sms_attachment_enabled_form: false,
                opt_in_settings_notification_sms_attachment_value_form: '',
                opt_in_settings_notification_sms_message_form: 'Hi {{first_name}},\n Your opt-in page is converting!\n {{first_name}} {{last_name}} {{email}}\n Opted into your {{playbook_name}} lead funnel.\n Send them a welcome message in thier client portal!\n Nice work!\n Team {{company_name}}',
                opt_in_settings_nurture_enabled_form: false,
                opt_in_settings_nurture_value_form: [2, 3, 4],
                opt_in_settings_pop_up_gdpr_permission_text_form: 'I agree to receive emails and text messages from {{company_name}}',
                opt_in_settings_pop_up_image_form: 'https://storage.googleapis.com/onboardme/uploads/media/33255/1280X720/om-image-1718360916-4817122-33255.png',
                opt_in_settings_pop_up_image_enabled_form: true,
                opt_in_settings_pop_up_opt_gdpr_permission_enabled_form: true,
                opt_in_settings_pop_up_opt_in_fields_email_enabled_form: true,
                opt_in_settings_pop_up_opt_in_fields_first_name_enabled_form: true,
                opt_in_settings_pop_up_opt_in_fields_last_name_enabled_form: true,
                opt_in_settings_pop_up_opt_in_fields_phone_enabled_form: false,
                opt_in_settings_pop_up_opt_in_headline_form: 'Where Should We Send Your Guide?',
                opt_in_settings_pop_up_privacy_statement_text_form: 'Delivered securely. Your information is safe.',
                opt_in_settings_pop_up_security_icon_color_form: '#2F7EED',
                opt_in_settings_pop_up_submit_button_text_form: 'Send Me The Guide!',
                opt_in_settings_security_text_form: 'Delivered securely. Your information is safe.',
                opt_in_settings_social_sharing_description_form: 'Enjoy Delicious Macros-Friendly Meals Without the Guilt',
                opt_in_settings_social_sharing_image_form: '',
                opt_in_settings_social_sharing_title_form: 'Get Your Free Dining Out Guide!',
                opt_in_settings_tagging_enabled_form: false,
                opt_in_settings_tagging_value_form: [2, 3, 4],
                sub_headline_form: 'Enjoy Delicious Macros-Friendly Meals Without the Guilt',
            };

            if (defaultValues[key]) {
                vm[key].setting_value = defaultValues[key];
            }

            if (key == 'opt_in_settings_confirmation_page_message_form' && defaultValues[key]) {
                vm.thankyouMessageDefaultValue = defaultValues[key];

                vm.thankyouMessageDefaultValueWatcher += 1;
            }
        },

        addBenefit () {
            const vm = this;

            if (vm.benefits_form.setting_value.length < 6) {
                vm.benefits_form.setting_value.push('New Benefit Text');

                vm.updateOptinSettings(vm.benefits_form).then((result) => {
                    if (result) {
                        Toastr.success('Benifit has been added successfully');
                        // vm.getOptinSettings({ id: vm.playbook.id, service_name: 'playbook' });
                    }
                });
            }
        },

        handleDeleteBenefit (arr, n) {
            const vm = this;

            arr.splice(n, 1);

            vm.updateOptinSettings(vm.benefits_form).then((result) => {
                if (result) {
                    Toastr.success('Benifit has been deleted successfully');
                    // vm.getOptinSettings({ id: vm.playbook.id, service_name: 'playbook' });
                }
            });
        },

        insertPersonalize (personalize, type, key = '') {
            const vm = this;
            const textarea    = document.getElementById(`${type}`);
            const caretPos    = textarea.selectionStart;
            const textAreaTxt = textarea.value;
            const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

            vm[key].setting_value = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
        },

        parseEmbedCode (url) {
            const vm = this;

            return VideoParser.embedCodeFromUrl(url);
        },

        handleTagCreate () {
            const vm = this;
            const form = vm.$refs['tag-form'];

            form.validate().then(({ valid }) => {
                if (valid) {
                    vm.tagForm.setFieldError = form.setFieldError;

                    vm.createTag(vm.tagForm).then((result) => {
                        vm.createTags = false;
                        vm.tagForm.name = '';
                        vm.getTags();
                    });
                }
            });
        },

        fillNotificationEmailValues () {
            const vm = this;

            vm.notificationEmail.subject    = vm.opt_in_settings_notification_email_subject_form.setting_value;
            vm.notificationEmail.email      = vm.opt_in_settings_notification_email_message_form.setting_value;
            vm.notificationSmsMedia         = vm.opt_in_settings_notification_sms_attachment_value_form.setting_value ? [vm.opt_in_settings_notification_sms_attachment_value_form.setting_value] : [];

            vm.notificationValuesFilled = true;
        },

        handleDefaultEmail () {
            const vm = this;

            vm.opt_in_settings_notification_email_subject_form.setting_value = '🔥[New Lead] {{playbook_name}} funnel';
            vm.opt_in_settings_notification_email_message_form.setting_value = 'Hi {{first_name}},\n\n Your opt-in page is converting!\n\n {{first_name}} {{last_name}} {{email}}\n\n Opted into your {{playbook_name}} lead funnel.\n\n Send them a welcome message in thier client portal!\n\n Nice work!\n\n Team {{company_name}}';
        },

        handleDefaultSms () {
            const vm = this;

            vm.opt_in_settings_notification_sms_message_form.setting_value = 'Hi {{first_name}},\n Your opt-in page is converting!\n {{first_name}} {{last_name}} {{email}}\n Opted into your {{playbook_name}} lead funnel.\n Send them a welcome message in thier client portal!\n Nice work!\n Team {{company_name}}';
        },

        updateSmsText () {
            const vm = this;

            const messageSms = vm.$refs['sms-component'];

            if (messageSms) {
                vm.notificationSmsText = messageSms.notificationSmsMedia && messageSms.notificationSmsMedia.media && messageSms.notificationSmsMedia.media[0] ? vm.opt_in_settings_notification_sms_message_form.setting_value + '\n\n' + messageSms.notificationSmsMedia.media[0].toString() : vm.opt_in_settings_notification_sms_message_form.setting_value;
            }
        },

        updateNotificationHasMediaField (status) {
            const vm = this;

            vm.opt_in_settings_notification_sms_attachment_enabled_form.setting_value = status ? true : false;
        },

        trackNotificationSMSFocusout (type) {
            const vm = this;

            if (vm.mounted) {
                if (type == 'content') {
                    vm.handleAutosave(vm.opt_in_settings_notification_sms_message_form, 'opt_in_settings_notification_sms_message_form');
                } else if (type == 'media') {
                    vm.handleAutosave(vm.opt_in_settings_notification_sms_attachment_value_form, 'opt_in_settings_notification_sms_attachment_value_form');
                } else if (type == 'has_media') {
                    vm.handleAutosave(vm.opt_in_settings_notification_sms_attachment_enabled_form, 'opt_in_settings_notification_sms_attachment_enabled_form');
                }
            }
        },

        trackThankyouMessageFocusout: debounce(function () {
            const vm    = this;
            const form  = vm.$refs['playbook-optin-form'];
            const data  = vm.opt_in_settings_confirmation_page_message_form;
            const key   = 'opt_in_settings_confirmation_page_message_form';

            if (data.id && vm.mounted) {
                data.setFieldError   = form.setFieldError;
                data.field           = key;

                vm.updateOptinSettings(data).then((result) => {
                    if (result) {
                        Toastr.success('Optin settings has been saved successfully');
                    }
                });
            }
        }, 500),

        trackNotificationEmailFocusout (type) {
            const vm = this;

            if (vm.mounted) {
                if (type == 'content') {
                    vm.handleAutosave(vm.opt_in_settings_notification_email_message_form, 'opt_in_settings_notification_email_message_form');
                } else if (type == 'subject') {
                    vm.handleAutosave(vm.opt_in_settings_notification_email_subject_form, 'opt_in_settings_notification_email_subject_form');
                }
            }
        },

        closeTutorialListDropdown(){
            const vm = this;

            vm.tutorialList = false;
        },

        handleTutorialVideo () {
            const vm = this;

            vm.tutorialVideo = 'https://www.loom.com/embed/3862b4f03fd24b0c911a7e32704b4eaa?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';

            vm.tutorial = true;
        },
    }
}
</script>

<style scoped>
.preview_area{
    background: #fff;
}
.global_setting{
    min-height: 100vh;
    height: auto;
}
.preview_button{
    position: fixed;
    right: 25px;
    top: 15px;
    padding: 5px 12px;
    background: #f5f5f5;
    border-radius: 15px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: #5a5a5a;
    cursor: pointer;
    margin: 0 0 0 15px;
    z-index: 2;
}
.main_panel{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 25px 30px 25px;
    background: #fff;
    position: relative;
    z-index: 2;
}
.playbook-optin-form{
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}
.optin_wrapper{
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    margin-top: 10px;
    flex-grow: 1;
}
.optin_header{
    padding: 10px 90px 30px 90px;
}
.company_label{
    font-size: 15px;
    line-height: 22px;
    color: #121525;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
}
.company_label .tag{
    padding: 5px 12px;
    background: var(--var-submit-btn-color-light);
    border-radius: 15px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    margin-left: 6px;
    position: relative;
}
.main_panel .company_label .rename_fld{
    max-width: 300px;
    margin: 0 0 0 15px;
}
.optin_title{
    font-size: 50px;
    line-height: 62px;
    color: #121525;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 55px 0 15px 0;
}
.optin_subtitle{
    font-size: 21px;
    line-height: 31px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.main_panel .rename_fld{
    padding-right: 46px;
    position: relative;
    margin-right: auto;
    margin-right: auto;
    width: 100%;
    display: none;
}
.main_panel .rename_fld input{
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    padding: 10px 15px;
    background: #f0f5ff;
    border-radius: 5px;
}
.main_panel .rename_fld textarea{
    width: 100%;
    height: 100px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #5a5a5a;
    padding: 10px 15px;
    background: #f0f5ff;
    border-radius: 5px;
}
.main_panel .rename_fld .save_btn{
    padding: 3px 8px;
    background: #2f7eed;
    color: #fff;
    border-radius: 3px;
    font-size: 8px;
    line-height: 10px;
    text-transform: uppercase;
    cursor: pointer;
    text-wrap: nowrap;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.optin_title label, .optin_subtitle label{
    position: relative;
}
.optin_body{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 100px 40px 100px;
    margin: auto 0;
}
.optin_body .img_vid_wpr{
    width: 55%;
    padding: 25px 35px;
    line-height: 0;
    position: relative;
}
.video_wpr{
    position: relative;
    padding-bottom: 56.25%;
}
.video_wpr :deep(iframe) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
}
.video-overlay {
    position: absolute;
    z-index: +9;
    height: 100%;
    width: 100%;
    top: 0;
}
.optin_body .img_vid_wpr img{
    width: 100%;
    border-radius: 6px;
}
.optin_body .optin_features{
    width: 45%;
    padding: 25px 35px 25px 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}
.optin_body .optin_features ul{
    margin-top: auto;
}
.optin_body .optin_features li{
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 12px 15px 12px 0;
    font-size: 19px;
    line-height: 26px;
    font-weight: 500;
    color: var(--var-benefit-text-color);
}
.optin_body .optin_features li .icon{
    width: var(--var-bullet-icon-size);
    height: var(--var-bullet-icon-size);
    margin: 9px 15px 0 0;
    transform: translateY(calc(0px - var(--var-bullet-icon-size) / 3));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.optin_body .optin_features li .icon svg{
    width: 100%;
    height: 100%;
}
.optin_body .feature_item label{
    display: block;
    position: relative;
    padding: 0;
}
.feature_item label .delete_btn{
    position: absolute;
    right: -60px;
    top: 4px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    background: #fff;
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.delete_btn img{
    max-height: 10px;
}
.add_benefit{
    padding: 7px 16px;
    background: #f5f5f5;
    border-radius: 15px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: #121525;
    cursor: pointer;
    margin-top: 18px;
}
.guide_btn_wpr{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    position: relative;
}
.guide_btn{
    min-width: 370px;
    margin: 30px 0 0 0;
    padding: 14px 30px;
    border-radius: 5px;
    background: #2f7eed;
    font-size: 17px;
    line-height: 22px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    position: relative;
    text-align: center;
}
.guide_btn .rename_fld .save_btn{
    border: 1px solid #f0f5ff;
}
.guide_btn_wpr .security{
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    margin-top: 15px;
    position: relative;
}
.guide_btn_wpr .security svg{
    width: 12px;
    height: 16px;
    margin-right: 8px;
}
.setting_btn{
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    font-size: 11px;
    line-height: 14px;
    border: 1px solid #e9e9e9;
    padding: 6px 12px;
    border-radius: 12px;
    background: #fff;
    color: #5a5a5a;
    cursor: pointer;
    margin-right: -70px;
}
.setting_btn i{
    font-size: 12px;
    padding-right: 7px;
    color: #777;
}
.setting_btn label{
    pointer-events: none;
}
.optin_features .setting_btn{
    position: absolute;
    right: 0;
    bottom: 70px;
}
.optin_footer .setting_btn{
    position: absolute;
    right: 100px;
    bottom: 0;
}
.company_label .edit_btn,
.optin_title label .edit_btn,
.optin_subtitle label .edit_btn,
.feature_item label .edit_btn,
.img_vid_wpr .edit_btn,
.guide_btn .edit_btn,
.security .edit_btn,
.copyright_title .edit_btn{
    position: absolute;
    right: 0;
    top: 4px;
    width: 20px;
    height: 20px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    background: #fff;
    color: #999;
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease-in-out;
}
.company_label .edit_btn{
    right: -30px;
}
.optin_title label .edit_btn{
    top: 13px;
    right: -40px;
}
.optin_subtitle label .edit_btn{
    right: -30px;
}
.feature_item label .edit_btn{
    right: -30px;
}
.img_vid_wpr .edit_btn{
    top: 40px;
    right: 50px;
    height: 32px;
    width: 32px;
    font-size: 15px;
    z-index: 10;
}
.guide_btn .edit_btn{
    right: 5px;
    top: 10px;
}
.security .edit_btn{
    right: -30px;
    top: 0;
}
.copyright_title .edit_btn{
    right: -25px;
}
.company_label:hover .edit_btn,
.optin_title:hover .edit_btn,
.optin_subtitle:hover .edit_btn,
.feature_item:hover .edit_btn,
.feature_item:hover .delete_btn,
.img_vid_wpr:hover .edit_btn,
.guide_btn:hover .edit_btn,
.security:hover .edit_btn,
.copyright_title:hover .edit_btn {
    opacity: 1;
}
.company_label.rename .tag,
.optin_title.rename label,
.optin_subtitle.rename label,
.feature_item.rename label,
.guide_btn.rename label,
.security.rename label,
.copyright_title.rename label{
    display: none;
}
.company_label.rename .rename_fld,
.optin_title.rename .rename_fld,
.optin_subtitle.rename .rename_fld,
.feature_item.rename .rename_fld,
.guide_btn.rename .rename_fld,
.security.rename .rename_fld,
.copyright_title.rename .rename_fld{
    display: flex;
}
.optin_footer{
    padding: 30px 45px 0 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 0 10px 10px;
    position: relative;
}
.optin_footer .copyright{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
}
.optin_footer .copyright_title{
    position: relative;
    padding: 4px 0;
    margin-left: 5px;
}
.optin_footer .privacy_policy{
    padding-left: 30px;
    position: relative;
    text-decoration: none;
    color: #5a5a5a;
}
.optin_footer .privacy_policy:before{
    position: absolute;
    content: '';
    left: 15px;
    top: 0;
    bottom: 0;
    border-left: 1px solid #b9b9b9;
}
.optin-setting-form {
    height: calc(100% - 77px);
    display: flex;
    flex-direction: column;
}
.optin-setting-form .modal_footer{
    position: sticky;
    bottom: 0;
    z-index: 3;
    margin-top: 30px;
}
.optin_list{
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}
.optin_list li{
    padding: 5px 0;
}
.optin_list li label{
    background: #f5f5f5;
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
}
.icon_section {
    padding: 20px 0;
}
.icon_section ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}
.icon_section ul li label {
    width: 36px;
    height: 36px;
    /* border: 1px solid #d9d9d9; */
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    cursor: pointer;
    filter: grayscale(1);
}
.icon_section ul li label img {
    max-width: 20px;
    max-height: 20px;
    width: auto;
}
.icon_section ul li label.active {
    /* border: 1px solid #8dbcff; */
    filter: grayscale(0)
}
.canva_template_cta{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    margin-top: 30px;
    background: #fff;
    border: 1px solid #deecff;
    border-radius: 25px;
    font-size: 15px;
    line-height: 20px;
    color: #2f7eed;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
}
.canva_template_cta img{
    max-height: 16px;
    width: auto;
    padding: 0 8px;
    margin-bottom: 3px;
}
:deep(.redactor-box .redactor-styles){
    resize: vertical;
}
:deep(.email_comp .additional_area){
    display: none;
}

.loader-box {
    min-height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company_label {
    position: relative;
}

.company_label .tutorial_btn{
    display: flex;
    align-items: center;
    position: relative;
    left: 0;
    position: absolute;
}
.company_label .tutorial_btn label {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #2f7eed;
    cursor: pointer;
    min-width: auto;
}
.company_label .tutorial_btn img {
    max-height: 18px;
    width: auto;
    margin-left: 5px;
    cursor: pointer;
}
.company_label .tutorial_btn .dropdown_wpr{
    min-width: max-content;
}
.company_label .tutorial_btn .dropdown_wpr:before{
    display: none;
}
.company_label .tutorial_btn .dropdown_wpr ul li{
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #5a5a5a;
}

@media(max-width: 1399px){
    .optin_header{
        padding: 40px 60px 30px 60px;
    }
    .optin_body{
        padding: 10px 60px 40px 60px;
    }
    .optin_body .img_vid_wpr,
    .optin_body .optin_features{
        padding: 25px;
    }
    .optin_body .optin_features li{
        font-size: 17px;
        line-height: 24px;
    }
    .setting_btn{
        margin-right: -40px;
    }
    .optin_footer .setting_btn{
        right: 60px;
    }
    .optin_features .setting_btn,
    .optin_footer .setting_btn{
        padding: 6px;
    }
    .optin_features .setting_btn label,
    .optin_footer .setting_btn label{
        /* display: none; */
    }
    .optin_features .setting_btn i,
    .optin_footer .setting_btn i{
        /* padding: 0; */
    }
}

@media(max-width: 1199px){
    .optin_header{
        padding: 40px 35px 30px 35px;
    }
    .optin_body{
        padding: 10px 35px 40px 35px;
    }
    .optin_title{
        font-size: 40px;
        line-height: 50px;
    }
    .optin_subtitle{
        font-size: 19px;
        line-height: 28px;
    }
    .optin_body .img_vid_wpr,
    .optin_body .optin_features{
        padding: 20px;
    }
    .guide_btn_wpr, .guide_btn{
        min-width: 100%;
    }
    .setting_btn{
        margin-right: -25px;
    }
    .optin_footer{
        padding: 30px 35px;
    }
    .optin_footer .setting_btn{
        right: 35px;
    }
}

@media(max-width: 992px){
    .playbook-optin-form{
        max-width: 750px;
    }
    .optin_header{
        padding-bottom: 10px;
    }
    .optin_body{
        padding-bottom: 20px;
    }
    .optin_title{
        font-size: 30px;
        line-height: 40px;
    }
    .optin_subtitle{
        font-size: 17px;
        line-height: 24px;
    }
    .setting_btn{
        margin-right: 10px;
    }
    .optin_body .img_vid_wpr,
    .optin_body .optin_features{
        width: 100%;
        padding: 15px 10px;
    }
    .optin_features .setting_btn{
        bottom: 110px;
    }
}
@media(max-width: 768px){
    .setting_btn{
        margin-right: 0;
    }
    .optin_body .img_vid_wpr,
    .optin_body .optin_features{
        padding: 15px 0;
    }
    .img_vid_wpr .edit_btn{
        top: 30px;
        right: 15px;
    }
    .optin_body .optin_features li{
        padding-right: 50px;
    }
    .optin_footer .privacy_policy{
        width: 100%;
        text-align: center;
        padding: 5px 0 0 0;
    }
    .optin_footer .privacy_policy:before{
        display: none;
    }
}

@media(max-width: 499px){
    .preview_button{
        right: 15px;
    }

    .company_label .tutorial_btn .dropdown_wpr{
        width: 300px;
        min-width: auto;
    }
}

@media(max-width: 480px){
    .main_panel{
        padding: 50px 0 30px 0;
    }
    .optin_header{
        padding: 30px 30px 10px 30px;
    }
    .optin_title label .edit_btn,
    .optin_subtitle label .edit_btn{
        right: -25px;
    }
    .optin_body {
        padding: 10px 20px 20px 20px;
    }
    .optin_footer{
        padding: 30px;
    }
    .company_label .tag{
        font-size: 13px;
        line-height: 18px;
    }
    .optin_footer .setting_btn{
        right: 20px;
    }
}
</style>